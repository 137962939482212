const navigation = [
  {
    name: "About",
  },
  {
    name: "Chat",
  },
];

export default navigation;
