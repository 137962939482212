const navigation = [
  {
    name: "About",
  },
  {
    name: "Organizations",
  },
];

export default navigation;
